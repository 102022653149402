<template>
  <div class="background">
    <b-row class="no-gutters mx-auto justify-content-center p_y__3">
      <b-col class="col-12 col-md-8 col-lg-6 col-xl-4 p-1 ">
        <div style="wdith: 100%; text-align: center">
          <img
            style="width: 250px"
            src="../assets/images/Logo_EmPower-2020.png"
            alt="Image"
            class="rounded-0"
          />
        </div>

        <b-card no-body class="py-2 px-3 border-0 rounded-0 bg-transparent">
          <!-- <h6 class="mb-0 text-red font-weight-bold text-uppercase">
            {{ $t("welcome") }}
          </h6> -->
          <!-- <hr class="my-2 mx-n3" /> -->

          <b-row class="no-gutters mt-2">
            <b-col class="col-12 col-sm-auto"> </b-col>
            <b-col class="col-12 col-sm">
              <b-card-body class="pt-sm-0 pl-sm-3 px-0 pb-1">
                <b-tabs
                  v-model="activeTab"
                  class="tab-container"
                  active-nav-item-class="bg-transparent"
                  nav-class="text-uppercase initialism"
                  content-class="mt-3"
                >
                  <b-tab
                    :title="$t('login')"
                    title-link-class="text-muted rounded-0 px-4"
                  >
                    <login-form />
                  </b-tab>
                  <b-tab
                    :title="$t('register')"
                    title-link-class="text-muted rounded-0 px-4"
                    lazy
                  >
                    <register-form @goToLoginTab="goToLoginTab" />
                  </b-tab>
                </b-tabs>
              </b-card-body>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import LoginForm from "./../components/LoginForm";
import RegisterForm from "./../components/RegisterForm";
export default {
  components: {
    LoginForm,
    RegisterForm
  },
  data() {
    return {
      activeTab: 0
    };
  },
  methods: {
    goToLoginTab() {
      this.activeTab = 0;
    }
  }
};
</script>

<style scoped>
.background {
  background: url("../assets/images/Login-page-network.png");
  background-size: 500px;
  background-repeat: no-repeat;
  background-position: right 0 top 0;
}

.tab-container >>> .nav-tabs .nav-link.active {
  border-top: 2px solid var(--primary);
  border-bottom-color: var(--light);
  font-weight: bold;
  background: transparent;
  color: var(--dark) !important;
}

.tab-container >>> .nav-tabs .nav-link {
  color: black;
}
</style>
