<template>
  <b-form>
    <b-form-group
      label-class="font-weight-bold"
      :label="$t('email')"
      label-for="email"
    >
      <b-form-input
        id="email"
        v-model="email"
        @input="email = email.toLowerCase()"
      ></b-form-input>
    </b-form-group>
    <b-form-group
      label-class="font-weight-bold"
      :label="$t('password')"
      label-for="password"
      class="password__input"
    >
      <b-input-group id="password">
        <b-form-input
          v-model="password"
          :type="passwordInputType"
          class="border-right-0"
        ></b-form-input>
        <b-input-group-append is-text>
          <b-icon
            :icon="passwordIcon"
            class="clickable"
            @click="changePasswordInputState"
          ></b-icon>
        </b-input-group-append>
        <b-input-group-append>
          <b-button
            variant="outline-secondary"
            class="input-append-btn"
            @click="passwordRevovery"
          >
            {{ $t("button.forgot") }}
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>
    <b-form-checkbox v-model="rememberMe">
      {{ $t("rememberMe") }}
    </b-form-checkbox>
    <b-row class="no-gutters mt-5">
      <b-col class="col-12">
        <b-button
          class="mb-1"
          variant="red"
          :disabled="!isFormValid"
          block
          @click="login"
        >
          {{ $t("button.login") }}
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      password: "",
      rememberMe: false,
      showPassword: false
    };
  },
  methods: {
    changePasswordInputState() {
      this.showPassword = !this.showPassword;
    },
    async login() {
      try {
        this.$store.commit("loader/SET_LOADER", {});
        await this.$store.dispatch("clearAll", null, { root: true });

        await this.$store.dispatch("auth/login", {
          email: this.email,
          password: this.password
        });

        await this.retrieveProjectCategories();
        await this.$store.dispatch("audience/retrieveCountries");
        await this.$store.dispatch("stimulus/retrieveVideoCategories");
        await this.$store.dispatch("auth/retrieveClient");

        this.$store.commit("auth/SET_IS_LOGGED_IN", true);
        this.$store.commit("settings/SET_TOUR_ACTIVE", this.isFirstLogin);

        const redirectRoute = this.$route.query.redirect;
        if (redirectRoute) {
          this.$router.replace(redirectRoute);
        } else {
          this.$router.replace({ name: "Dashboard" });
        }
      } catch {
      } finally {
        this.$store.dispatch("loader/close");
      }
    },
    async retrieveProjectCategories() {
      try {
        await this.$store.dispatch("audience/retrieveProjectCategories", false);
      } catch (error) {
        console.log(error);
      }
    },
    passwordRevovery() {
      this.$router.push({ name: "PasswordRecovery" });
    }
  },
  computed: {
    isFormValid() {
      return this.email.length > 0 && this.password.length > 0;
    },
    isFirstLogin() {
      return this.$store.getters["auth/isFirstLogin"];
    },
    passwordInputType() {
      return this.showPassword ? "text" : "password";
    },
    passwordIcon() {
      return this.showPassword ? "eye" : "eye-slash";
    }
  }
};
</script>

<style scoped>
.input-append-btn {
  border: 1px solid #ced4da;
}
.password__input >>> .input-group-text {
  background-color: white;
  border-left: unset;
}
.password__input >>> input {
  min-width: 160px;
}
</style>
